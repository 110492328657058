<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addEmail"
      v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Email
    </button>
    <alert class="my-4" v-if="emails.isLoading" />
    <alert
      class="my-4"
      v-if="!emails.isLoading && emails.data.length == 0"
      :hideLoader="true"
      >No Emails</alert
    >
    <div
      class="card border-0 overflow-auto mobile-tablet-no-card p-2 p-md-0"
      v-if="!emails.isLoading && emails.data.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
            <th
              v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in emails.data" :key="email.id">
            <td>
              <b class="mobile-tablet-only mr-2">Number:</b
              ><a :href="`mailto:${email.email}`">{{ email.email }}</a>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b>
              <badge
                class="font-100"
                :color="email.unsubscribed ? `danger` : `success`"
                v-text="email.unsubscribed ? `Unsubscribed` : `Subscribed`"
              />
            </td>

            <td
              class="text-right"
              :class="`${emails.data.length > 1 ? 'td-actions' : ''}`"
              v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
            >
              <button
                v-if="emails.data.length > 1"
                class="btn btn-danger btn-sm"
                @click="deleteEmail(email)"
                :disabled="email.isDeleting"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <icon type="trash" v-if="!email.isDeleting" />
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="email.isDeleting"
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "providerEmail",
  computed: {
    ...mapState(["providers"]),
    ...mapState("providers", ["emails"]),
  },
  mounted() {
    this.get(this.providers.provider.user_id);
  },
  methods: {
    ...mapActions({
      get: "providers/emails/get",
    }),
    addEmail: function () {
      this.$router.push({
        name: "providers.emails.create",
        params: { id: this.$route.params.id },
        query: { src: "emails" },
      });
    },
    deleteEmail: function (email) {
      email.isDeleting = true;
      let vm = this;
      vm.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this email?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          vm.$store.dispatch("providers/emails/remove", {
            user: vm.providers.provider.user_id,
            id: email.id,
          });
        } else {
          email.isDeleting = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.font-100 {
  font-size: 100%;
}
</style>
